import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
   
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16V19C14 20.105 14.895 21 16 21H19C20.105 21 21 20.105 21 19V16C21 14.895 20.105 14 19 14H16C14.895 14 14 14.895 14 16Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5V8C14 9.105 14.895 10 16 10H19C20.105 10 21 9.105 21 8V5C21 3.895 20.105 3 19 3L16 3C14.895 3 14 3.895 14 5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 16L3 19C3 20.105 3.895 21 5 21H8C9.105 21 10 20.105 10 19V16C10 14.895 9.105 14 8 14H5C3.895 14 3 14.895 3 16Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 5.79971H10"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 2.2998V4.2998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.95 9.9999H4.05C3.4704 9.9999 3 9.5295 3 8.9499V4.5168C3 3.9372 3.4704 3.4668 4.05 3.4668H8.95C9.5296 3.4668 10 3.9372 10 4.5168V8.9499C10 9.5295 9.5296 9.9999 8.95 9.9999Z"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 2.2998V4.2998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
