import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16V19C14 20.105 14.895 21 16 21H19C20.105 21 21 20.105 21 19V16C21 14.895 20.105 14 19 14H16C14.895 14 14 14.895 14 16Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5V8C14 9.105 14.895 10 16 10H19C20.105 10 21 9.105 21 8V5C21 3.895 20.105 3 19 3L16 3C14.895 3 14 3.895 14 5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 16L3 19C3 20.105 3.895 21 5 21H8C9.105 21 10 20.105 10 19V16C10 14.895 9.105 14 8 14H5C3.895 14 3 14.895 3 16Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={6.5}
      cy={5}
      r={2}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 3C7.60457 3 8.5 3.89543 8.5 5C8.5 6.10457 7.60457 7 6.5 7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11C10 9.89543 9.10457 9 8 9H5C3.89543 9 3 9.89543 3 11"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
