import React from 'react'
import Icon from '../components/icons/Icon'

export default function Home() {
  return (
    <div className=' pl-[212px] pr-12  w-full h-full flex flex-col '>
        <div className='mt-[40px]'>
            <img src={'/images/logo.png'} className='w-[155px] h-auto' alt="" />

        </div>
        <div className=' w-full flex-1 flex flex-col justify-center'>
            <div className='max-w-[700px]'>
                <p className='title-level1'>Search, networking, events,...</p>
                <p className='text-level1'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur sunt tempora nesciunt quas? Harum ab quas officia animi aspernatur. </p>

            </div>
            <div className="flex flex-warp gap-x-8 mt-[78px]">
                <div className="w-[312px] space-y-3">
                    <div className="w-full h-[220px] bg-black text-white flex justify-center items-center rounded">
                        <Icon className='w-[100px] h-[100px]' name='Qexplorer'/>
                    </div>
                    <div className=''>
                        <div className="flex space-x-2 items-center">
                            <p className='text-level2 font-bold'>Qorddu Explorer</p>
                        </div>
                        <p className='text-level2'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea quibusdam explicabo eveniet
                        </p>
                        <button className='mt-4 flex space-x-2 items-center' onClick={() => window.open('https://www.qorddu.com','_blank')}>
                            <Icon className='icon-sm' name='Open'/>
                            <p>Open</p>
                        </button>
                    </div>
                </div>
                <div className="w-[312px] space-y-3 ">
                    <div className="w-full h-[220px] bg-black text-white flex justify-center items-center rounded">
                        <Icon className='w-[100px] h-[100px]' name='QContact'/>
                    </div>
                    <div className=''>
                        <div className="flex space-x-2 items-center">
                            <p className='text-level2 font-bold'>Qorddu Contact</p>
                        </div>
                        <p className='text-level2'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea quibusdam explicabo eveniet
                        </p>
                        <button className='mt-4 flex space-x-2 items-center'>
                            <Icon className='icon-sm' name='Calendar'/>
                            <p>Q2 2025</p>
                        </button>
                    </div>
                </div>
                <div className="w-[312px] space-y-3 ">
                    <div className="w-full h-[220px] bg-black text-white flex justify-center items-center rounded">
                        <Icon className='w-[100px] h-[100px]' name='Qcalendar'/>
                    </div>
                    <div className=''>
                        <div className="flex space-x-2 items-center">
                            <p className='text-level2 font-bold'>Qorddu Calendar</p>
                        </div>
                        <p className='text-level2'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea quibusdam explicabo eveniet
                        </p>
                        <button className='mt-4 flex space-x-2 items-center'>
                            <Icon className='icon-sm' name='Calendar'/>
                            <p>Q4 2025</p>
                        </button>
                    </div>
                </div>
                <div className="w-[312px] space-y-3 ">
                    <div className="w-full h-[220px] bg-black text-white flex justify-center items-center rounded">
                        <Icon className='w-[100px] h-[100px]' name='Qnews'/>
                    </div>
                    <div className=''>
                        <div className="flex space-x-2 items-center">
                            <p className='text-level2 font-bold'>Qorddu News</p>
                        </div>
                        <p className='text-level2'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea quibusdam explicabo eveniet
                        </p>
                        <button className='mt-4 flex space-x-2 items-center'>
                            <Icon className='icon-sm' name='Calendar'/>
                            <p>Q2 2026</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}
