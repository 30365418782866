import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 16V19C14 20.105 14.895 21 16 21H19C20.105 21 21 20.105 21 19V16C21 14.895 20.105 14 19 14H16C14.895 14 14 14.895 14 16Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5V8C14 9.105 14.895 10 16 10H19C20.105 10 21 9.105 21 8V5C21 3.895 20.105 3 19 3L16 3C14.895 3 14 3.895 14 5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 16L3 19C3 20.105 3.895 21 5 21H8C9.105 21 10 20.105 10 19V16C10 14.895 9.105 14 8 14H5C3.895 14 3 14.895 3 16Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46813 2.48512C6.6156 2.18796 6.91871 2 7.25045 2C7.5822 2 7.88531 2.18796 8.03278 2.48512L8.66804 3.77866C8.79584 4.03742 9.04347 4.21612 9.32932 4.25586L10.7569 4.46295C11.0851 4.5112 11.3576 4.74145 11.4599 5.05704C11.5622 5.37263 11.4766 5.71894 11.2391 5.95057L10.2067 6.95799C10.0018 7.15847 9.90831 7.44674 9.95658 7.72931L10.2007 9.1469C10.2563 9.47402 10.1217 9.8044 9.85335 9.99956C9.585 10.1947 9.22923 10.221 8.93515 10.0673L7.65762 9.395C7.4039 9.26194 7.10101 9.26194 6.84729 9.395L5.56975 10.0683C5.27567 10.222 4.91991 10.1957 4.65155 10.0006C4.3832 9.8054 4.24863 9.47502 4.30423 9.1479L4.54933 7.73031C4.59707 7.44777 4.50368 7.15975 4.29922 6.95899L3.26279 5.95157C3.02512 5.71939 2.93997 5.37235 3.04319 5.05653C3.14641 4.74071 3.42007 4.51095 3.74899 4.46395L5.17659 4.25686C5.4623 4.21678 5.70979 4.03819 5.83786 3.77966L6.46813 2.48512Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
