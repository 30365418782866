import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider } from 'react-router-dom';


import { AppContextProvider } from './context/appContext';

import './i18n'; 
import Home from './pages';





const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    
    <Route 
      element={<AppContextProvider><Outlet/></AppContextProvider>}
    >
       {/* Redirect from root to default language page */}
       <Route path="/" element={<Navigate to={`/en/`} replace />} />
       <Route path="/:lng" element={<Home/>} />

        
        
   

    </Route>
    
    </>

  )
);
root.render(
  <RouterProvider router={router} />
);

